.not-found{
   
 
    min-height: 100vh;
  
   

    background-image: url('https://images.unsplash.com/photo-1619864066877-926b1c1d5a1a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}


.div-not-found{
    display: flex;
    flex-direction: column;
    align-items: center;

}

.div-time-out{
    display: flex;
    flex-direction: column;
    align-items: center;
}